export const initialValue = [
    {
        id : 1, 
        region : "Yasamal",
        metro : "Elmlər", 
        price : "400", 
        rooms : "2", 
        area : "50", 
        floor : "2",
        category : "Mənzillər",
        images : [
        {image : "assets/images/apartment/apartment-three.png"},
        {image : "assets/images/apartment/apartment-one.png"},
        {image : "assets/images/apartment/apartment-three.png"},
        {image : "assets/images/apartment/apartment-one.png"},
            ], 
        description : "Amorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae nibh nisl. Cras etitikis mauris egeth lorem ultricies ferme is ntum a inti diam. Morbi mollis pellden tesque offs aiug ueia nec rhoncus. Nam ute ultricies. Cras etitikis mauris eget lorem ultricies ferme ntum a inti diam. Morbi mollis pellen tesque offs aiug ueia nec rhoncus. Nam ute ultricies.",
        time : "20/06/2021",
    },
    {
        id : 2, 
        region : "Binəqədi",
        metro : "Azadlıq", 
        price : "300", 
        rooms : "2", 
        area : "70", 
        floor : "7",
        category : "Mənzillər",
        images : [
            {image : "assets/images/apartment/apartment-three.png"},
            {image : "assets/images/apartment/apartment-three.png"},
            {image : "assets/images/apartment/apartment-three.png"},
            {image : "assets/images/apartment/apartment-three.png"},
                ],  
        description : "Amorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae nibh nisl. Cras etitikis mauris egeth lorem ultricies ferme is ntum a inti diam. Morbi mollis pellden tesque offs aiug ueia nec rhoncus. Nam ute ultricies. Cras etitikis mauris eget lorem ultricies ferme ntum a inti diam. Morbi mollis pellen tesque offs aiug ueia nec rhoncus. Nam ute ultricies.",
        time : "21/06/2021",
    },
    {
        id : 3, 
        region : "Nəsimi",
        metro : "Memar Əcəmi", 
        price : "400", 
        rooms : "4", 
        area : "40", 
        floor : "4",
        category : "Mənzillər",
        images : [
            {image : "assets/images/apartment/apartment-three.png"},
            {image : "assets/images/apartment/apartment-three.png"},
            {image : "assets/images/apartment/apartment-three.png"},
            {image : "assets/images/apartment/apartment-three.png"},
                ],  
        description : "Amorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae nibh nisl. Cras etitikis mauris egeth lorem ultricies ferme is ntum a inti diam. Morbi mollis pellden tesque offs aiug ueia nec rhoncus. Nam ute ultricies. Cras etitikis mauris eget lorem ultricies ferme ntum a inti diam. Morbi mollis pellen tesque offs aiug ueia nec rhoncus. Nam ute ultricies.",
        time : "22/06/2021",
    },
    {
        id : 4, 
        region : "Hövsan",
        metro : "", 
        price : "250", 
        rooms : "2", 
        area : "34", 
        floor : "1",
        category : "Yaşayış evləri",
        images : [
            {image : "assets/images/apartment/apartment-three.png"},
            {image : "assets/images/apartment/apartment-three.png"},
            {image : "assets/images/apartment/apartment-three.png"},
            {image : "assets/images/apartment/apartment-three.png"},
                ], 
        description : "Amorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae nibh nisl. Cras etitikis mauris egeth lorem ultricies ferme is ntum a inti diam. Morbi mollis pellden tesque offs aiug ueia nec rhoncus. Nam ute ultricies. Cras etitikis mauris eget lorem ultricies ferme ntum a inti diam. Morbi mollis pellen tesque offs aiug ueia nec rhoncus. Nam ute ultricies.",
        time : "23/06/2021",
    },
    {
        id : 5, 
        region : "Yasamal",
        metro : "20 yanvar", 
        price : "2500", 
        rooms : "8", 
        area : "120", 
        floor : "6",
        category : "Ofislər",
        images : [
            {image : "assets/images/apartment/apartment-three.png"},
            {image : "assets/images/apartment/apartment-three.png"},
            {image : "assets/images/apartment/apartment-three.png"},
            {image : "assets/images/apartment/apartment-three.png"},
                ], 
        description : "Amorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae nibh nisl. Cras etitikis mauris egeth lorem ultricies ferme is ntum a inti diam. Morbi mollis pellden tesque offs aiug ueia nec rhoncus. Nam ute ultricies. Cras etitikis mauris eget lorem ultricies ferme ntum a inti diam. Morbi mollis pellen tesque offs aiug ueia nec rhoncus. Nam ute ultricies.",
        time : "24/06/2021",
    },
    {
        id : 6, 
        region : "Xətai",
        metro : "Həzi Aslanov", 
        price : "5000", 
        rooms : "15", 
        area : "220", 
        floor : "1",
        category : "Şirkət binaları",
        images : [
            {image : "assets/images/apartment/apartment-three.png"},
            {image : "assets/images/apartment/apartment-three.png"},
            {image : "assets/images/apartment/apartment-three.png"},
            {image : "assets/images/apartment/apartment-three.png"},
                ],  
        description : "Amorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae nibh nisl. Cras etitikis mauris egeth lorem ultricies ferme is ntum a inti diam. Morbi mollis pellden tesque offs aiug ueia nec rhoncus. Nam ute ultricies. Cras etitikis mauris eget lorem ultricies ferme ntum a inti diam. Morbi mollis pellen tesque offs aiug ueia nec rhoncus. Nam ute ultricies.",
        time : "25/06/2021",
    },
]